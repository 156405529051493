var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"teros-elevation",attrs:{"color":_vm.tableColor}},[_c('v-card-title',{staticClass:"px-5 text-uppercase v-title-cadastro"},[_vm._v(" CÁLCULO DO INCREMENTO DE PESQUISADORES ")]),_c('v-card-text',[_c('div',{staticClass:"d-flex pt-0"},[_c('v-menu',{attrs:{"offset-y":"","dense":"","max-width":"160px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"table-v-action-button mr-2"},'div',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-export-outline")]),_vm._v(" Exportação ")],1)]}}])},[_c('v-list',[_c('v-list-item',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.handleExportCsv()}}},[_c('v-list-item-title',{staticClass:"v-label-input"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-delimited-outline")]),_vm._v(" Exportar CSV ")],1)],1),_c('v-list-item',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.handleExportXlsx()}}},[_c('v-list-item-title',{staticClass:"v-label-input"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-excel-outline")]),_vm._v(" Exportar XLSX ")],1)],1)],1)],1),_c('v-select',{staticClass:"mb-n1 mt-0 mr-3",staticStyle:{"max-width":"170px"},attrs:{"prepend-icon":"mdi-calendar","prefix":"Ano Base:","items":_vm.anosBase,"dense":""},on:{"change":function($event){return _vm.doLoad()}},model:{value:(_vm.anoBase),callback:function ($$v) {_vm.anoBase=$$v},expression:"anoBase"}})],1)]),_c('v-data-table',{staticClass:"mb-7",attrs:{"data-app":"","headers":_vm.headerStyle(_vm.colunas.detalhes),"items":_vm.detalhes,"footer-props":{
      'items-per-page-options': [-1],
    },"hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
    var headers = ref.headers;
return [_c('tr',{staticClass:"table-v-tr",staticStyle:{"cursor":"default"}},_vm._l((headers),function(col){return _c('td',{key:col.key},[_vm._v(" "+_vm._s(col.format ? col.format(item[col.value]) : item[col.value])+" ")])}),0)]}}])}),_c('span',{staticClass:"m-3 d-block font-weight-bold"},[_vm._v("Cálculo Ano: "+_vm._s(_vm.anoAnterior && _vm.anoAnterior[0] ? _vm.anoAnterior[0].ano : ""))]),_c('v-data-table',{staticClass:"mb-7",attrs:{"data-app":"","headers":_vm.headerStyle(_vm.colunas.ano),"items":_vm.anoAnterior,"footer-props":{
      'items-per-page-options': [-1],
    },"hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
    var headers = ref.headers;
return [_c('tr',{staticClass:"table-v-tr",staticStyle:{"cursor":"default"}},_vm._l((headers),function(col){return _c('td',{key:col.key},[_vm._v(" "+_vm._s(col.format ? col.format(item[col.value]) : item[col.value])+" ")])}),0)]}},{key:"body.append",fn:function(){return [_c('tr',{staticClass:"table-v-tr",staticStyle:{"cursor":"default"}},[_c('th',{attrs:{"colspan":"3"}}),_c('th',[_vm._v("Total")]),_c('th',[_vm._v(_vm._s(_vm._f("toDouble")(_vm.totalAnoAnterior.media)))]),_c('th',[_vm._v(_vm._s(_vm.totalAnoAnterior.acrescimo))]),_c('th',[_vm._v(_vm._s(_vm.totalAnoAnterior.incentivo))])])]},proxy:true}],null,true)}),_c('span',{staticClass:"m-3 d-block font-weight-bold"},[_vm._v("Cálculo Ano: "+_vm._s(_vm.anoAtual && _vm.anoAtual[0] ? _vm.anoAtual[0].ano : ""))]),_c('v-data-table',{staticClass:"mb-7",attrs:{"data-app":"","headers":_vm.headerStyle(_vm.colunas.ano),"items":_vm.anoAtual,"footer-props":{
      'items-per-page-options': [-1],
    },"hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
    var headers = ref.headers;
return [_c('tr',{staticClass:"table-v-tr",staticStyle:{"cursor":"default"}},_vm._l((headers),function(col){return _c('td',{key:col.key},[_vm._v(" "+_vm._s(col.format ? col.format(item[col.value]) : item[col.value])+" ")])}),0)]}},{key:"body.append",fn:function(){return [_c('tr',{staticClass:"table-v-tr",staticStyle:{"cursor":"default"}},[_c('th',{attrs:{"colspan":"3"}}),_c('th',[_vm._v("Total")]),_c('th',[_vm._v(_vm._s(_vm._f("toDouble")(_vm.totalAnoAtual.media)))]),_c('th',[_vm._v(_vm._s(_vm.totalAnoAtual.acrescimo))]),_c('th',[_vm._v(_vm._s(_vm.totalAnoAtual.incentivo))])])]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }